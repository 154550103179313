import Image from 'next/image';
import Location from 'svg/Location';
import Star from 'svg/Star';
import Tournament from 'svg/Tournament';
import Users from 'svg/Users';
import { GroupCardProps } from './props';

const DEFAULT_GROUP_IMAGE = '/images/app/default_group_image.png';

const GroupCard: React.FC<GroupCardProps> = ({ group }) => {
  const iconClass = 'h-4 w-4 text-color-text-lightmode-icon shrink-0';

  const members = group.members.map(({ userProfile }) => userProfile?.preferredName).join(', ');

  return (
    <div className="flex gap-4">
      <div className="relative h-32 w-32 shrink-0 overflow-hidden rounded-full">
        <Image
          src={group.profilePhotoProviderUrl || DEFAULT_GROUP_IMAGE}
          fill
          alt={'profile-image'}
        />
      </div>
      <div>
        <span className="typography-product-subheading text-color-bg-lightmode-primary-primary dark:text-color-bg-darkmode-primary">
          {group.title}
        </span>
        <div className="typography-product-caption mt-2 flex flex-col  gap-2 text-color-text-lightmode-secondary dark:text-color-bg-lightmode-secondary">
          <span className="flex gap-1">
            <Users className={iconClass} /> {group?.memberDisplayCount || '-'}
          </span>

          <span className="flex gap-1">
            <Location className={iconClass} /> {group.addressString || '-'}
          </span>

          <span className="flex gap-1">
            <Star className={iconClass} /> {group.members.length}{' '}
            {group.members.length > 1 ? 'Coaches' : 'Coach'}
          </span>
          <span className="flex gap-1">
            <Tournament className={iconClass} /> {group.events.length}{' '}
            {group.events.length > 1 ? 'Tournaments' : 'Tournament'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
