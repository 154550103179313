import { useEffect, useState } from 'react';
import Stepper from 'screens/TournamentsBuilder/components/Stepper';
import ChooseLocationStep from './ChooseLocationStep';
import ChooseTimeSlotStep from './ChooseTimeSlotStep';
import SelectCoachStep from './SelectCoachStep';
import { BookLessonProps } from '../props';

const BookLessonDesktop: React.FC<BookLessonProps> = ({
  lessons,
  venues,
  activeLesson,
  setActiveLesson,
  lessonsLoading,
  venuesLoading,
  setActiveLocation,
  activeLocation,
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  selectedDate,
  handleBookLesson,
  nearbyVenues,
}) => {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    setActiveStep(selectedDate ? 3 : activeLocation ? 2 : 1);
  }, [selectedDate, activeLocation]);

  return (
    <section className="px-12 pt-ds-2xl max-sm:hidden md:mb-20">
      <h2 className="typography-product-heading text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
        Book a Lesson
      </h2>
      <div className="mt-ds-2xl rounded-2xl border border-color-border-input-lightmode p-ds-xl shadow-sm dark:border-color-border-input-darkmode">
        <div className="flex justify-center">
          <Stepper
            steps={[
              { title: 'Select a coach', description: '' },
              { title: 'Choose a location', description: '' },
              { title: 'Pick a date', description: '' },
            ]}
            currentStep={activeStep}
            className="max-w-full"
          />
        </div>
        <div className="mt-7 flex gap-8 max-xl:flex-col">
          <SelectCoachStep
            loading={lessonsLoading}
            lessons={lessons}
            handleLessonSelect={(lesson) => setActiveLesson(lesson)}
            activeLesson={activeLesson}
          />
          <ChooseLocationStep
            setActiveLocation={setActiveLocation}
            activeLocation={activeLocation}
            venues={venues}
            venuesLoading={venuesLoading}
            nearbyVenues={nearbyVenues}
          />
          <ChooseTimeSlotStep
            activeOwnerLessons={activeOwnerLessons}
            coachAvailability={coachAvailability}
            handleSelectSlot={handleSelectSlot}
            selectedDate={selectedDate}
            handleBookLesson={handleBookLesson}
          />
        </div>
      </div>
    </section>
  );
};

export default BookLessonDesktop;
