import { LESSONS_PAGE } from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import CardsSection from 'components/CardSection';
import { CardSectionMode } from 'components/CardSection/props';
import Link from 'components/Link';
import LessonCard from '../LessonCard';
import { SelectCoachStepProps } from '../props';

const SelectCoachStep: React.FC<SelectCoachStepProps> = ({
  lessons,
  activeLesson,
  handleLessonSelect,
  loading,
}) => {
  return (
    <div className="min-w-0 flex-1">
      <CardsSection
        loading={loading}
        className="xl:h-[35rem]"
        gap="1rem"
        visibleCards={{ base: 1, md: 2, lg: 3, xl: 5 }}
        scrollOffset={{ base: 60 }}
        mode={{
          base: CardSectionMode.HORIZONTAL,
          xl: CardSectionMode.VERTICAL,
          xxl: CardSectionMode.VERTICAL,
        }}
        data={lessons}
        renderCard={(item, index) => (
          <LessonCard.Desktop
            key={index}
            isActive={activeLesson?.id === item.id}
            onClick={() => handleLessonSelect(item)}
            lesson={item}
          />
        )}
      />
      {lessons.length > 5 && (
        <div className="mt-ds-2xl flex justify-center">
          <Link
            href={LESSONS_PAGE}
            className="typography-product-button-label-medium flex cursor-pointer select-none items-center gap-1 text-color-brand-primary"
          >
            View all Coaches <ArrowRight className="h-4 w-4" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default SelectCoachStep;
