import Button from 'components/Button';
import LessonCalendar from 'components/LessonCalendar';
import { ChooseTimeSlotStepProps } from '../props';

const ChooseTimeSlotStep: React.FC<ChooseTimeSlotStepProps> = ({
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  selectedDate,
  handleBookLesson,
}) => {
  return (
    <div className="min-w-0 flex-1">
      <span className="typography-product-subheading mb-5 inline-block xl:hidden">Select Date</span>
      <div className="rounded-xl border p-4">
        <div className="h-[34rem]">
          <LessonCalendar
            lessons={activeOwnerLessons || []}
            isOwner={false}
            showName={false}
            coachAvailability={coachAvailability}
            isAnonymous
            onSlotSelect={handleSelectSlot}
          />
        </div>
        <div className="flex justify-center">
          <Button
            variant="brand"
            disabled={!selectedDate}
            sizeDesktop="md"
            className="mt-ds-xl w-fit"
            onClick={handleBookLesson}
          >
            Book a Lesson
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseTimeSlotStep;
