import { memo, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { TOURNAMENTS_PAGE, TOURNAMENT_QUICK_CREATE_PAGE, getEventUrl } from 'constants/pages';
import { useGetTournamentsByLocationLazyQuery } from 'types/generated/client';
import { getImageUrl } from 'services/client/cloudflare/getImageUrl';
import { milesToMeters } from 'utils/shared/geo/milesToMeters';
import { useGeoLocation } from 'hooks/useGeoLocation';
import ArrowRight from 'svg/ArrowRight';
import ChevronLeft from 'svg/ChevronLeft';
import ChevronRight from 'svg/ChevronRight';
import { ButtonLink } from 'components/Button';
import CardsSection from 'components/CardSection';
import { TOURNAMENT_DISTANCE_IMPERIAL_OPTIONS } from 'components/Explore/Tournaments/utils';
import Card from 'components/cards/CardTournament';
import classNames from 'styles/utils/classNames';
import { TournamentSectionProps } from './props';
import { getDecoratedTournaments } from './utils';

const MemoizedCard = memo(Card);

const TournamentSection: React.FC<TournamentSectionProps> = () => {
  const { centerLatitude, centerLongitude, position } = useGeoLocation();
  const [getTournamentsByLocationLazyQuery, { data: tournaments, loading: tournamentsLoading }] =
    useGetTournamentsByLocationLazyQuery();

  const decoratedTournaments = useMemo(
    () => getDecoratedTournaments(tournaments?.events || [], centerLatitude, centerLongitude),
    [tournaments, centerLatitude, centerLongitude],
  );

  useEffect(() => {
    const init = async () => {
      await getTournamentsByLocationLazyQuery({
        variables: {
          limit: 20,
          distance: milesToMeters(
            TOURNAMENT_DISTANCE_IMPERIAL_OPTIONS[TOURNAMENT_DISTANCE_IMPERIAL_OPTIONS.length - 1]
              .id,
          ),
          from: {
            type: 'Point',
            coordinates: [position?.longitude, position?.latitude],
          },
        },
      });
    };
    init();
  }, [getTournamentsByLocationLazyQuery, position]);

  return (
    <div
      className={classNames(
        'peer/tournaments mt-6 md:mb-20',
        !tournamentsLoading && decoratedTournaments.length === 0 && 'hidden',
      )}
    >
      <h2
        className={classNames(
          'md:typography-product-heading typography-product-subheading text-color-text-lightmode-primary dark:text-color-bg-darkmode-primary max-sm:px-4 sm:px-12',
        )}
      >
        Play in a Tournament
      </h2>

      <CardsSection
        gap={'1rem'}
        scrollOffset={{ base: 50, md: 0 }}
        className="mt-8 max-sm:mt-4 max-sm:pl-4 sm:px-12"
        loading={tournamentsLoading}
        data={decoratedTournaments}
        nextArrow={
          <div className="grid h-10 w-10 place-items-center rounded-full bg-color-bg-lightmode-primary shadow-md dark:bg-color-bg-darkmode-primary">
            <ChevronRight className="h-4 w-4" />
          </div>
        }
        prevArrow={
          <div className="grid h-10 w-10 place-items-center rounded-full bg-color-bg-lightmode-primary shadow-md dark:bg-color-bg-darkmode-primary">
            <ChevronLeft className="h-4 w-4" />
          </div>
        }
        visibleCards={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
        renderCard={(tournament) => (
          <Link
            key={tournament.id}
            className="block 2xl:max-w-[26rem]"
            href={getEventUrl(tournament.id)}
          >
            <MemoizedCard
              tournamentId={tournament.id}
              coverImageUrl={
                tournament.coverImagePath
                  ? getImageUrl({
                      url: `${process.env.CLOUDFLARE_PUBLIC_URL}${tournament.coverImagePath}`,
                      path: tournament.coverImagePath,
                    })
                  : ''
              }
              organizerImageUrl={
                tournament.organizerImagePath
                  ? getImageUrl({
                      url: `${process.env.CLOUDFLARE_PUBLIC_URL}${tournament.organizerImagePath}`,
                      path: tournament.organizerImagePath,
                    })
                  : ''
              }
              title={tournament.title}
              registrationClosedAt={tournament.registrationClosedAt}
              startTimestamp={tournament.startTimestamp}
              status={tournament.status}
              sourceOrganizerTitle={tournament.sourceOrganizerTitle}
              sourceRegistrationCount={tournament.sourceRegistrationCount}
              registrationCount={tournament.registrationsAggregate?.aggregate?.count || 0}
              isExternal={tournament.isExternal}
              displayDate={tournament.displayDate}
              displayLocation={
                tournament.city?.name && tournament.city?.countrySubdivision?.code
                  ? `${tournament.city?.name}, ${tournament.city?.countrySubdivision?.code}`
                  : tournament.displayLocation
              }
              distance={tournament.distance}
              showImageAndPeople={true}
            />
          </Link>
        )}
      />
      <div className="mt-10 flex w-full gap-2  px-20 max-sm:mt-6 max-sm:px-4 sm:justify-end">
        <ButtonLink
          variant="inverted"
          sizeMobile="sm"
          sizeDesktop="lg"
          className="shrink sm:max-w-fit"
          href={TOURNAMENTS_PAGE}
        >
          View All Tournaments
        </ButtonLink>
        <ButtonLink
          variant="brand"
          sizeMobile="sm"
          sizeDesktop="lg"
          className="shrink sm:max-w-fit"
          iconRight={<ArrowRight className="h-4 w-4" />}
          href={TOURNAMENT_QUICK_CREATE_PAGE}
        >
          I Run Tournaments
        </ButtonLink>
      </div>
    </div>
  );
};

export default TournamentSection;
