import { memo, useMemo } from 'react';
import Link from 'next/link';
import { MY_GROUPS_PAGE } from 'constants/pages';
import { calculateHaversineDistance } from 'utils/shared/geo/calculateHaversineDistance';
import { useGeoLocation } from 'hooks/useGeoLocation';
import ArrowRight from 'svg/ArrowRight';
import ChevronLeft from 'svg/ChevronLeft';
import ChevronRight from 'svg/ChevronRight';
import Button, { ButtonLink } from 'components/Button';
import GroupCard from 'components/Explore/Groups/GroupCard';
import classNames from 'styles/utils/classNames';
import { GroupSectionProps } from './props';
import CardsSection from '../../components/CardSection/CardsSection';

const MemoizedGroupCard = memo(GroupCard);

const GroupSection: React.FC<GroupSectionProps> = ({ groups, loading }) => {
  const { centerLatitude, centerLongitude } = useGeoLocation();

  const decoratedGroups = useMemo(() => {
    return (groups || []).map((group) => ({
      ...group,
      distance: calculateHaversineDistance({
        coord1: {
          latitude: centerLatitude || 0,
          longitude: centerLongitude || 0,
        },
        coord2: {
          latitude: group.latitude,
          longitude: group.longitude,
        },
        unit: 'miles',
      }),
    }));
  }, [groups, centerLatitude, centerLongitude]);

  const leadCommunityMailto = useMemo(() => {
    const email = 'community@bounce.game';
    const subject = 'I lead a community';
    const body = encodeURIComponent(
      'I am interested in leading a community and would like to get more information.',
    );

    return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${body}`;
  }, []);

  return (
    <section
      className={classNames('mb-16 max-sm:mb-12', !loading && groups.length === 0 && 'hidden')}
    >
      <h2 className="typography-product-subheading lg:typography-product-heading flex justify-between gap-1 px-12 max-sm:px-4">
        Join a Group
      </h2>
      <CardsSection
        gap="1rem"
        className="mt-8 max-sm:mt-4 max-sm:pl-4 sm:px-12"
        data={decoratedGroups}
        visibleCards={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
        scrollOffset={{ base: 50, md: 0 }}
        renderCard={(group) => (
          <Link href={`groups/${group.slug}`}>
            <MemoizedGroupCard group={group} />
          </Link>
        )}
        loading={loading}
        nextArrow={
          <div className="grid h-10 w-10 place-items-center rounded-full bg-color-bg-lightmode-primary shadow-md dark:bg-color-bg-darkmode-primary">
            <ChevronRight className="h-4 w-4" />
          </div>
        }
        prevArrow={
          <div className="grid h-10 w-10 place-items-center rounded-full bg-color-bg-lightmode-primary shadow-md dark:bg-color-bg-darkmode-primary">
            <ChevronLeft className="h-4 w-4" />
          </div>
        }
      />
      <div className="mt-10 flex gap-2 px-20 max-sm:mt-6 max-sm:px-4 sm:justify-end">
        <ButtonLink
          variant="inverted"
          sizeMobile="sm"
          sizeDesktop="lg"
          className="shrink sm:max-w-fit"
          href={MY_GROUPS_PAGE}
        >
          View All Groups
        </ButtonLink>
        <ButtonLink
          href={leadCommunityMailto}
          variant="brand"
          sizeMobile="sm"
          sizeDesktop="lg"
          className="shrink sm:max-w-fit"
          iconRight={<ArrowRight className="h-4 w-4" />}
        >
          I Lead a Community
        </ButtonLink>
      </div>
    </section>
  );
};

export default GroupSection;
