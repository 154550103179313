const getTitleWithCount = (title: React.ReactNode, count: React.ReactNode) => (
  <div className="flex flex-1 flex-col gap-1 text-center max-md:basis-1/2">
    <span className=" max-sm:typography-informative-quote  typography-informative-display-primary-mobile font-extrabold text-color-text-lightmode-secondary dark:text-color-bg-darkmode-secondary">
      {count}
    </span>
    <span className="max-sm:typography-product-button-label-medium typography-product-heading-compact-desktop text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
      {title}
    </span>
  </div>
);

export default function StatsSection() {
  /**
   * @todo find solution so we don't have to hardcode, could do as part of SSG and use vercel KV to cache it
   */
  /**
   * @note this is limited by query size limits and won't be accuarate
   */
  // const [getTournamentStatsLazyQuery, { data }] = useGetTournamentStatsLazyQuery();

  return (
    <section className="container mx-auto  mt-4 max-md:mt-0">
      <div className="flex flex-wrap justify-between gap-y-6  rounded-2xl border border-color-border-card-lightmode px-14 py-10 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] dark:border-color-border-card-darkmode max-sm:py-ds-lg">
        {getTitleWithCount('Courts', '15k+')}
        {getTitleWithCount('Coaches', '200+')}
        {getTitleWithCount(
          <>
            <span className="lg:hidden">Tournaments</span>
            <span className="hidden lg:inline">Upcoming Tournaments</span>
          </>,
          '600+',
        )}
        {getTitleWithCount('Communities', '1000+')}
      </div>
    </section>
  );
}
