import {
  COACHES_LANDING_PAGE,
  COACH_MARKETPLACE_PAGE,
  COURT_FINDER_PAGE,
  TOURNAMENTS_PAGE,
} from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import Button, { ButtonLink, ButtonText } from 'components/Button';
import Link from 'components/Link';

const HeroSection: React.FC = () => {
  const buttons = (
    <>
      <ButtonLink
        sizeDesktop="lg"
        sizeMobile="md"
        variant="brand"
        className="w-fit  max-sm:w-full"
        href={COACH_MARKETPLACE_PAGE}
      >
        Book Lessons
      </ButtonLink>
      <ButtonLink
        href={COURT_FINDER_PAGE}
        sizeDesktop="lg"
        sizeMobile="md"
        variant="inverted"
        className="w-fit max-sm:w-full"
      >
        Discover Courts
      </ButtonLink>
      <ButtonLink
        href={TOURNAMENTS_PAGE}
        sizeDesktop="lg"
        sizeMobile="md"
        variant="inverted"
        className="w-fit  max-sm:w-full"
      >
        Find Tournaments
      </ButtonLink>
    </>
  );

  return (
    <section className="mx-auto w-full py-12 sm:container max-sm:pl-4">
      <div className="flex gap-7">
        <div className="flex flex-[0.6] items-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary max-md:flex-[0.5] ">
          <div className="flex max-w-[41.25rem] flex-col ">
            <h1 className="max-sm:typography-informative-subheading typography-informative-display-secondary-mobile">
              The platform powering amateur
              <mark className="max-sm:typography-informative-body-superhighlight typography-informative-display-mega-primary-mobile ml-2 bg-transparent max-sm:text-2xl">
                pickleball
              </mark>
            </h1>
            <p className="max-sm:typography-product-text-card-mobile typography-informative-subheading-compact mt-2">
              Helping players, coaches, and communities to maximize time on court.
            </p>
            <div className="mt-ds-2xl flex flex-wrap items-start justify-start gap-4 max-sm:hidden">
              {buttons}
            </div>
            <div className="max-sm:typography-informative-body-superhighlight typography-informative-subheading-medium-highlight  mt-20 flex gap-4 max-sm:mt-3 max-sm:flex-col max-sm:gap-1">
              <p>Are you a racket sports coach?</p>
              <Link
                href={COACHES_LANDING_PAGE}
                className="max-sm:typography-informative-body-superhighlight typography-informative-subheading-medium-highlight  flex items-center gap-1 text-color-text-brand"
              >
                <span className="shrink-0">Learn More</span>
                <ArrowRight className="ml-1 h-5 w-5 shrink-0" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-[0.4] shrink-0 max-md:flex-[0.5]">
          <img
            src={'/images/players/hero-section-iphone.png'}
            alt="hero-section-iphone"
            className="h-auto w-full max-w-[27.2rem] max-sm:hidden"
          />
          <img
            src="/images/players/hero-section-mob.png"
            alt="hero-section-mobile"
            className="ml-auto hidden h-full w-full max-w-56 max-sm:block"
          />
        </div>
      </div>
      <div className="mt-10 hidden flex-col gap-5 px-8 max-sm:flex">{buttons}</div>
    </section>
  );
};

export default HeroSection;
