import React, { ReactElement } from 'react';
import { VenueAccessTypesEnum } from 'types/generated/client';
import { milesToMeters } from 'utils/shared/geo/milesToMeters';
import FilterBox from 'components/forms/FilterBox/FilterBox';
import { SliderNumberRange } from 'components/forms/Slider';
import Switch from 'components/forms/Switch/Switch';
import { CourtFiltersProps } from './props';
import {
  VENUE_ACCESS_OPTIONS,
  VENUE_COURT_TYPE_OPTIONS,
  VENUE_DISTANCE_IMPERIAL_OPTIONS,
  VENUE_NETS_OPTIONS,
  VENUE_SURFACE_OPTIONS,
} from './utils';

const SectionTitle = ({ children }: { children: React.ReactNode }): ReactElement => {
  return (
    <p className="typography-product-subheading mb-2 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
      {children}
    </p>
  );
};

const CourtFilters: React.FC<CourtFiltersProps> = ({
  setAccessType,
  accessType,
  setCourtType,
  courtType,
  nets,
  setNets,
  surface,
  setSurface,
  courtsMinNumber,
  courtsMaxNumber,
  setShowOnlyDedicatedCourts,
  showOnlyDedicatedCourts,
  setCourtsMaxNumber,
  setCourtsMinNumber,
  courtLimit,
  distance,
  setDistance,
  fetchCourts,
  centerLatitude,
  centerLongitude,
}) => {
  return (
    <div className="flex flex-auto flex-col gap-8 overflow-y-auto px-4 py-6 md:px-ds-3xl">
      <div className="flex justify-between gap-2">
        <SectionTitle>Dedicated courts</SectionTitle>
        <Switch
          isActive={showOnlyDedicatedCourts}
          toggleIsActive={() => setShowOnlyDedicatedCourts(!showOnlyDedicatedCourts)}
        />
      </div>
      <div className="flex justify-between gap-2">
        <SectionTitle>Free courts</SectionTitle>
        <Switch
          isActive={accessType.length === 1 && accessType[0] === VenueAccessTypesEnum.Free}
          toggleIsActive={() =>
            accessType.length === 1 && accessType[0] === VenueAccessTypesEnum.Free
              ? setAccessType([])
              : setAccessType([VenueAccessTypesEnum.Free])
          }
        />
      </div>
      <div className="md:hidden">
        <SectionTitle>Distance</SectionTitle>
        <div className="grid grid-cols-3 gap-4">
          {VENUE_DISTANCE_IMPERIAL_OPTIONS.map((newDistance) => (
            <FilterBox
              key={newDistance.id}
              label={newDistance.name}
              onClick={() => {
                setDistance(newDistance);
                fetchCourts({
                  longitude: centerLongitude,
                  latitude: centerLatitude,
                  distance: milesToMeters(newDistance.id),
                });
              }}
              isSelected={distance.id === newDistance.id}
            />
          ))}
        </div>
      </div>
      <div className="block">
        <div className="mb-12">
          <SectionTitle>Number of Courts</SectionTitle>
        </div>
        <SliderNumberRange
          rangeMinimum={1}
          rangeMaximum={courtLimit}
          valueMinimum={courtsMinNumber}
          valueMaximum={courtsMaxNumber}
          setValueMinumum={(newMinimum) => setCourtsMinNumber(newMinimum)}
          setValueMaximum={(newMaximum) => setCourtsMaxNumber(newMaximum)}
          step={1}
          decimals={2}
        />
      </div>
      <div>
        <SectionTitle>Access</SectionTitle>
        <div className="grid grid-cols-3 gap-4">
          {VENUE_ACCESS_OPTIONS.map((access) => (
            <FilterBox
              key={access.id}
              label={access.name}
              onClick={() =>
                setAccessType((prev) => {
                  if (prev.includes(access.id)) {
                    return prev.filter((item) => item !== access.id);
                  }
                  return [...prev, access.id];
                })
              }
              isSelected={accessType.includes(access.id)}
            />
          ))}
        </div>
      </div>
      <div>
        <SectionTitle>Court type</SectionTitle>
        <div className="grid grid-cols-3 gap-4">
          {VENUE_COURT_TYPE_OPTIONS.map((type) => (
            <FilterBox
              key={type.id}
              label={type.name}
              onClick={() =>
                setCourtType((prev) => {
                  if (prev.includes(type.id)) {
                    return prev.filter((item) => item !== type.id);
                  }
                  return [...prev, type.id];
                })
              }
              isSelected={courtType.includes(type.id)}
            />
          ))}
        </div>
      </div>
      <div>
        <SectionTitle>Nets</SectionTitle>
        <div className="grid grid-cols-3 gap-4">
          {VENUE_NETS_OPTIONS.map((net) => (
            <FilterBox
              key={net.id}
              label={net.name}
              onClick={() =>
                setNets((prev) => {
                  if (prev.includes(net.id)) {
                    return prev.filter((item) => item !== net.id);
                  }
                  return [...prev, net.id];
                })
              }
              isSelected={nets.includes(net.id)}
            />
          ))}
        </div>
      </div>
      {/**
       * @note the lines data is bad. It says most lines are permanent, but our assumption is that this is including tennis lines
       */}
      {/* <div>
    <SectionTitle>Lines</SectionTitle>
    <div className="grid grid-cols-3 gap-4">
      {VENUE_LINES_OPTIONS.map((line) => {
        return (
          <FilterBox
            key={line.id}
            label={line.name}
            onClick={() => setLines(line.name)}
            isSelected={lines === line.name}
          />
        );
      })}
    </div>
  </div> */}
      <div>
        <SectionTitle>Surface</SectionTitle>
        <div className="grid grid-cols-3 gap-4">
          {VENUE_SURFACE_OPTIONS.map((surfaces) => {
            return (
              <FilterBox
                key={surfaces.id}
                label={surfaces.name}
                onClick={() =>
                  setSurface((prev) => {
                    if (prev.includes(surfaces.id)) {
                      return prev.filter((item) => item !== surfaces.id);
                    }
                    return [...prev, surfaces.id];
                  })
                }
                isSelected={surface.includes(surfaces.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourtFilters;
