import React, { Fragment, memo } from 'react';
import CloseIcon from 'svg/CloseIcon';
import CourtFlat from 'svg/CourtFlat';
import { ButtonText } from 'components/Button';
import CardVenue from 'components/cards/CardVenue';
import classNames from 'styles/utils/classNames';
import { CourtMapMarkerProps } from './props';

const MemoizedCourtFlat = memo(CourtFlat);
const MemoizedCardVenue = memo(CardVenue);

const CourtMapMarker: React.FC<CourtMapMarkerProps> = ({
  listHoveredVenueId,
  setListHoveredVenueId,
  setActiveVenueId,
  activeVenue,
  venue,
}) => {
  return (
    <Fragment>
      <div
        onClick={() => setActiveVenueId(venue.id)}
        onMouseLeave={() => setListHoveredVenueId(null)}
        onMouseEnter={() => setListHoveredVenueId(venue.id)}
        className={classNames(
          'group relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-full shadow-[0px_4px_4px_0px_rgba(0,0,0,0.16)]',
          listHoveredVenueId === venue.id
            ? 'z-20 bg-color-bg-lightmode-brand text-color-text-lightmode-invert dark:bg-color-bg-lightmode-brand dark:text-color-text-lightmode-invert'
            : 'bg-color-bg-lightmode-primary text-color-text-lightmode-secondary dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-secondary',
        )}
      >
        <MemoizedCourtFlat className="h-4 w-4" />
      </div>
      {activeVenue && activeVenue?.id === venue.id && (
        <div className="absolute left-7 top-1/2 isolate z-60 ml-4  flex w-[20rem] -translate-y-1/2 transform justify-center rounded-t-xl bg-color-bg-lightmode-primary p-ds-lg dark:bg-color-bg-darkmode-primary">
          <div className="absolute right-6 top-6 z-10 flex items-center justify-center rounded-full bg-color-bg-lightmode-invert bg-opacity-50">
            <ButtonText
              onClick={() => setActiveVenueId(null)}
              className="flex items-center justify-center rounded-full p-2"
            >
              <CloseIcon className="h-6 w-6 text-color-text-lightmode-invert" />
            </ButtonText>
          </div>
          <MemoizedCardVenue
            key={activeVenue.id}
            id={activeVenue.id}
            slug={activeVenue.slug}
            imageUrl={activeVenue?.images?.[0]?.url}
            title={activeVenue.title}
            addressString={activeVenue.addressString}
            accessType={activeVenue.accessType}
            totalCourtCount={activeVenue.indoorCourtCount + activeVenue.outdoorCourtCount}
            pickleballNets={activeVenue.pickleballNets}
          />
        </div>
      )}
    </Fragment>
  );
};

export default CourtMapMarker;
