import { useState } from 'react';
import { LESSONS_PAGE } from 'constants/pages';
import { useModal } from 'hooks/useModal';
import ArrowRight from 'svg/ArrowRight';
import { ButtonText } from 'components/Button';
import CardsSection from 'components/CardSection/CardsSection';
import Link from 'components/Link';
import BookLessonModal from './BookLessonModal';
import LocationPicker from './LocationPicker';
import LessonCard from '../LessonCard';
import { BookLessonProps } from '../props';

const BookLessonMobile: React.FC<BookLessonProps> = ({
  lessons,
  lessonsLoading,
  setActiveLesson,
  venues,
  setActiveLocation,
  activeLocation,
  activeOwnerLessons,
  coachAvailability,
  handleSelectSlot,
  selectedDate,
  handleBookLesson,
  nearbyVenues,
}) => {
  const bookLessonModal = useModal();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleCardClick = (index: number) => {
    setActiveLesson(lessons[index]);
    setActiveIndex(index);
  };

  return (
    <div className="mt-8 rounded-2xl border bg-color-bg-lightmode-primary py-8 shadow-sm dark:bg-color-bg-darkmode-primary sm:hidden">
      <div className="flex justify-between gap-1 px-20 max-sm:px-4">
        <h2 className="typography-product-subheading">Book a lesson</h2>
        <Link
          href={LESSONS_PAGE}
          className="typography-product-button-label-small flex cursor-pointer select-none items-center gap-1 text-color-brand-primary"
        >
          View all Coaches <ArrowRight className="h-3 w-3" />
        </Link>
      </div>
      <CardsSection
        gap="1rem"
        loading={lessonsLoading}
        className="max-sm:pl-4"
        data={lessons}
        visibleCards={{ base: 2, md: 4, xl: 5 }}
        scrollOffset={{ base: 0, xs: 30 }}
        renderCard={(item, index) => (
          <LessonCard.Mobile
            key={item.id}
            isActive={index === activeIndex}
            onClick={() => handleCardClick(index)}
            lesson={item}
          />
        )}
      />
      <LocationPicker
        handlePickTime={bookLessonModal.openModal}
        // @ts-ignore Need to allow for either
        venues={venues?.length ? venues : nearbyVenues}
        setActiveLocation={setActiveLocation}
        activeLocation={activeLocation}
        nearbyVenues={nearbyVenues}
      />
      <BookLessonModal
        activeOwnerLessons={activeOwnerLessons}
        coachAvailability={coachAvailability}
        isOpen={bookLessonModal.isOpen}
        handleClose={bookLessonModal.closeModal}
        activeLocation={activeLocation}
        handleSelectSlot={handleSelectSlot}
        selectedDate={selectedDate}
        handleBookLesson={handleBookLesson}
      />
    </div>
  );
};

export default BookLessonMobile;
