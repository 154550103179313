import { memo } from 'react';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import { loadingSkeletonGrid } from 'components/DiscoverPage/utils';
import CardVenue from 'components/cards/CardVenue';
import classNames from 'styles/utils/classNames';
import { CourtListProps } from './props';

const MemoizedCardVenue = memo(CardVenue);

const CourtList: React.FC<CourtListProps> = ({
  venues,
  listHoveredVenueId,
  setListHoveredVenueId,
  loading,
}) => {
  return !loading && venues.length === 0 ? (
    <NoDataPlaceholder text="No courts nearby. Update your location above to find places to play near you." />
  ) : (
    <div className="mx-auto flex w-full flex-col pb-8 pt-ds-xl">
      <div className="mx-auto grid w-full grid-cols-1 gap-ds-xl px-4 sm:px-ds-xl md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
        {loading
          ? loadingSkeletonGrid.map((Item, i) => <Item key={i} />)
          : venues.map((venue) => (
              <div
                key={venue.id}
                className={classNames(
                  'w-full flex-shrink-0 overflow-hidden rounded-lg lg:max-w-[26rem]',
                  listHoveredVenueId === venue.id &&
                    'ring-2 ring-color-bg-lightmode-invert ring-offset-8 ring-offset-color-bg-darkmode-invert dark:ring-color-bg-darkmode-invert dark:ring-offset-color-bg-lightmode-invert',
                )}
                onMouseEnter={() => setListHoveredVenueId(venue.id)}
                onMouseLeave={() => setListHoveredVenueId(null)}
              >
                {/* Cards */}
                <MemoizedCardVenue
                  key={venue.id}
                  id={venue.id}
                  slug={venue.slug}
                  imageUrl={venue?.images?.[0]?.url}
                  title={venue.title}
                  addressString={venue.addressString}
                  accessType={venue.accessType}
                  totalCourtCount={venue.indoorCourtCount + venue.outdoorCourtCount}
                  pickleballNets={venue.pickleballNets}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default CourtList;
