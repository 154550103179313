import Button from 'components/Button';
import { GradientBackground } from './utils';

const StayConnectedSection = () => {
  return (
    <section className="relative isolate my-6 flex gap-3 rounded-2xl border bg-color-bg-lightmode-primary px-4  py-8 text-color-text-lightmode-primary shadow-sm dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-primary">
      <GradientBackground left="30%" className="max-sm:hidden" />
      <img
        src="/images/players/coach-image.png"
        alt="coach-image"
        className="aspect-[1/1] max-h-[16rem] max-md:hidden"
      />
      <div className="flex grow-[2] justify-center lg:pt-10">
        <div className="max-w-[30rem] max-md:w-auto">
          <span className="max-md:typography-product-heading-compact-desktop typography-informative-display-primary-mobile ">
            Stay connected
          </span>
          <p className="typography-product-caption mt-4">
            Obsessed with playing pickleball? So are we.
            <br /> <br /> Subscribe to our newsletter and we’ll keep you in the loop on all things
            amateur pickleball.
          </p>
          <img
            src="/images/players/stay-connected.png"
            alt="stay-connected-1"
            className="mt-2 hidden w-full max-md:block"
          />
          <div className="max-mt-2 relative mt-6 h-12">
            <input
              className="typography-product-body w-full rounded-3xl border border-color-border-input-lightmode pr-32 focus:border-0  dark:border-color-border-input-darkmode"
              placeholder="Your email"
            />
            <Button
              sizeMobile="md"
              variant="brand"
              className="absolute right-[3px] top-[3px] max-w-28"
            >
              Subscribe
            </Button>
          </div>
        </div>
      </div>
      <img
        src="/images/players/stay-connected.png"
        alt="stay-connected"
        className="mt-16 aspect-[1/1] h-[16rem] max-md:hidden"
      />
    </section>
  );
};

export default StayConnectedSection;
