import { GetCoachesByGeoQuery } from 'types/generated/client';

export const isCoachProfile = (coach: any): coach is GetCoachesByGeoQuery['userProfiles'][0] => {
  return !!coach.username;
};

export const VENUE_DISTANCE_IMPERIAL_OPTIONS = [
  {
    name: '10 miles',
    id: 10,
  },
  {
    name: '25 miles',
    id: 25,
  },
  {
    name: '50 miles',
    id: 50,
  },
  {
    name: '100 miles',
    id: 100,
  },
];

export const DEFAULT_COURT_DISTANCE_IMPERIAL = VENUE_DISTANCE_IMPERIAL_OPTIONS[1];
