import { AccountType } from 'constants/user';
import ArrowRight from 'svg/ArrowRight';
import Check from 'svg/Check';
import Button from 'components/Button';
import { ConnectionSectionProps } from './props';
import { GradientBackground } from './utils';

const ConnectionSection: React.FC<ConnectionSectionProps> = ({ handleRegistration }) => {
  const getPointsWrapper = (
    title: string,
    points: string[],
    btnProps: { onClick: () => void; btnTitle: string },
  ) => (
    <div className="max-md:w-full">
      <div
        className="rounded-3xl bg-color-bg-lightmode-primary px-6 py-4
    text-color-text-lightmode-primary shadow-[0_4px_16px_0_rgba(0,0,0,0.04)] dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-primary"
      >
        <span className="typography-informative-heading-mobile max-md:typography-product-card-title-desktop">
          {title}
        </span>
        <div className="mt-5 flex flex-col gap-5">
          {points.map((point, index) => (
            <div className="flex items-center  gap-ds-sm" key={index}>
              <div className="grid  h-9 w-9 shrink-0  place-items-center  rounded-full shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)] max-md:max-h-6 max-md:max-w-6">
                <Check className="h-4 w-4 text-color-text-brand" />
              </div>
              <span className="typography-informative-quote max-md:typography-informative-caption-highlight">
                {point}
              </span>
            </div>
          ))}
        </div>
      </div>
      <Button
        sizeDesktop="lg"
        className="mt-14 max-md:mt-ds-md"
        variant="brand"
        onClick={btnProps.onClick}
        iconRight={<ArrowRight className="h-5 w-5" />}
      >
        {btnProps.btnTitle}
      </Button>
    </div>
  );

  return (
    <section className="relative isolate  mx-auto mt-24 sm:container max-sm:mt-12 max-sm:px-4">
      <GradientBackground left="20%" />
      <div className="grid place-items-center">
        <div className="flex max-w-[38.75rem] flex-col gap-2 text-center">
          <h2 className="typography-informative-display-secondary-mobile max-md:typography-informative-subheading-medium-highlight">
            Seamlessly connecting players and coaches
          </h2>
          <span className="max-md:typography-informative-caption typography-informative-subheading-compact text-color-text-lightmode-secondary dark:text-color-text-lightmode-secondary">
            Discover, connect and grow with the best coaches in the game.
          </span>
        </div>
      </div>
      <div className="mt-12 flex items-center max-md:flex-col max-md:gap-8 max-md:px-10">
        {getPointsWrapper('For Players', ['Find Coaches', 'Book Lessons', 'Track progress'], {
          onClick: () => handleRegistration(AccountType.Player),
          btnTitle: 'I am a Player',
        })}
        <div className="grid grow-[2] place-content-center  max-md:hidden">
          <img
            src={'/images/players/connection-iphone.png'}
            alt="connection-iphone.png"
            className="h-auto w-full max-w-[38.125rem] "
          />
        </div>
        {getPointsWrapper(
          'For Coaches',
          ['Managing Bookings', 'Streamline Payments', 'Advertise services'],
          {
            onClick: () => handleRegistration(AccountType.Coach),
            btnTitle: 'I am a Coach',
          },
        )}
      </div>
    </section>
  );
};

export default ConnectionSection;
